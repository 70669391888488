import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import MainLogo from "../../assets/shanikawa home logo.png"
import { Navbar, Container } from 'react-bootstrap';

function Nav() {
    const history = useHistory();

    const Logout = () => {
        localStorage.clear();
        history.push("/");
    };

    if (localStorage.getItem("loginAccess") !== "true") {
        return (
            <Navbar expand="md" className="navbar navbar-expand-lg navbar-custom">
                <Container>
                    <Navbar.Brand href="https://shanikawa.lk/">
                        <img
                            src={MainLogo}
                            alt="Shanikawa.LK"
                            className="navbar-site-logo"
                        />
                        <span className="nav-site-name">Shanikawa.LK</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="collapse navbar-collapse">
                        <div className="web-view">
                            <ul className="navbar-nav">
                                <li className="nav-item nav-left-li">
                                    <a className="nav-link active all-ads" aria-current="page" href="/all_ads">
                                        All Ads
                                    </a>
                                </li>
                                <li className="nav-item localization-btn">
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-outline-light">සිං</button>
                                        <button type="button" className="btn btn-outline-light active">En</button>
                                        <button type="button" className="btn btn-outline-light">த</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="web-view ms-auto">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item nav-left-li">
                                    <a className="nav-link active" aria-current="page" href="/login">
                                        Login
                                    </a>
                                </li>
                                <li className="nav-item nav-left-li">
                                    <a className="nav-link" href="/register">
                                        Register
                                    </a>
                                </li>
                                <li className="nav-item nav-left-li">
                                    <button className="btn btn-warning" style={{ fontWeight: "bold" }}>POST AD</button>
                                </li>
                            </ul>
                        </div>
                        <br/>
                        <div className="mobile-view">
                            <div className="d-flex">
                                <ul className="navbar-nav align-items-start">
                                    <li className="nav-item nav-left-li">
                                        <a className="nav-link active all-ads" aria-current="page" href="/all_ads">
                                            All Ads
                                        </a>
                                    </li>
                                    <li className="nav-item localization-btn">
                                        <div className="btn-group" role="group">
                                            <button type="button" className="btn btn-outline-light">සිං</button>
                                            <button type="button" className="btn btn-outline-light active">En</button>
                                            <button type="button" className="btn btn-outline-light">த</button>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="navbar-nav ms-auto align-items-end">
                                    <li className="nav-item nav-left-li nav-mobile-lr">
                                        <a className="nav-link" aria-current="page" href="/login">
                                            Login
                                        </a>
                                    </li>
                                    <li className="nav-item nav-left-li nav-mobile-lr">
                                        <a className="nav-link" href="/register">
                                            Register
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <br/>
                            <ul className="navbar-nav align-items-center">
                                <li className="nav-item nav-left-li">
                                    <button className="btn btn-warning" style={{ fontWeight: "bold" }}>POST AD</button>
                                </li>
                            </ul>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    } else {
        return (
            <Navbar expand="md" className="navbar navbar-expand-lg navbar-custom">
                <Container>
                    <Navbar.Brand href="https://shanikawa.lk/">
                        <img
                            src={MainLogo}
                            alt="Shanikawa.LK"
                            className="navbar-site-logo"
                        />
                        <span className="nav-site-name">Shanikawa.LK</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="collapse navbar-collapse">
                        <ul className="navbar-nav">
                            <li className="nav-item nav-left-li">
                                <a className="nav-link active all-ads" aria-current="page" href="/all_ads">
                                    All Ads
                                </a>
                            </li>
                            <li className="nav-item localization-btn">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-outline-light">සිං</button>
                                    <button type="button" className="btn btn-outline-light active">En</button>
                                    <button type="button" className="btn btn-outline-light">த</button>
                                </div>
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item nav-left-li">
                                <a className="nav-link active" aria-current="page" href="/login">
                                    Login
                                </a>
                            </li>
                            <li className="nav-item nav-left-li">
                                <a className="nav-link" href="/register">
                                    Register
                                </a>
                            </li>
                            <li className="nav-item nav-left-li">
                                <button className="btn btn-warning" style={{ fontWeight: "bold" }}>POST AD</button>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Nav;
