import React from 'react';
import './register.css';
import Container from "react-bootstrap/Container";
import { Carousel, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { FaFacebook, FaGoogle } from 'react-icons/fa'
import lg1 from "../../../assets/lg1.png"
import lg2 from "../../../assets/lg2.png"
import lg3 from "../../../assets/lg3.png"

const Register = () => {
  return (
    <Container>
      <br />
      <div id="register" className="web-view">
        <Row>
          <Col className='col-6'>
            <div className="card-view-login">
              <Form>
                <h2 className="mb-4">Register</h2>
                <FaFacebook className='login-icons' />
                <FaGoogle className='login-icons' />
                <br />
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label className="left-label">First Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter First Name" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formLastName">
                      <Form.Label className="left-label">Last Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter Last Name" />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formBasicEmail" className="mt-3">
                  <Form.Label className="left-label">Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="mt-3">
                  <Form.Label className="left-label">Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" />
                </Form.Group>
                <br />
                <Button type="submit" className="mt-4 w-100 btn btn-warning" style={{ fontWeight: "bold" }}>
                  Register
                </Button>
                <br />
                <br />
                <h6>Already have an account?</h6>
                <Button type="submit" className="mt-4 w-100 btn btn-dark" style={{ backgroundColor:"#000",fontWeight: "bold" }}>
                  Login
                </Button>
              </Form>
            </div>
          </Col>
          <Col className='col-6'>
          <Carousel className='carousel_border'>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={lg1}
                  alt="First slide"
                  style={{ borderRadius: '10px' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={lg2}
                  alt="Second slide"
                  style={{ borderRadius: '10px' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={lg3}
                  alt="Third slide"
                  style={{ borderRadius: '10px' }}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <br />
      </div>
      <div id="register" className="mobile-view">
        <div className="card-view">
          <Form>
            <h2 className="mb-4">Register</h2>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="left-label">First Name</Form.Label>
              <Form.Control type="text" placeholder="Enter First Name" />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="mt-3">
              <Form.Label className="left-label">Last Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Last Name" />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="mt-3">
              <Form.Label className="left-label">Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="mt-3">
              <Form.Label className="left-label">Username</Form.Label>
              <Form.Control type="text" placeholder="Enter Username" />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label className="left-label">Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <br />
            <Button type="submit" className="mt-4 w-100 btn btn-warning">
              Register
            </Button>
            <br />
            <br />
            <h6>Already have an account?</h6>
            <Button type="submit" className="mt-4 w-100 btn btn-dark">
              Login
            </Button>
          </Form>
        </div>
        <br />
      </div>
    </Container>
  );
}

export default Register;
