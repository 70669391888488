import "./App.css";
import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/SiteMain/Home";
import Nav from "./components/SiteMain/Nav";
import Footer from "./components/SiteMain/footer";
import Login from "./components/UserPages/login/login";
import Register from "./components/UserPages/register/register";
import ButterToast, { POS_RIGHT, POS_TOP } from "butter-toast";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Nav />
        <div className="contents">
          <Switch>
            <Route path="/login" render={() => <Login />} />
            <Route path="/register" render={() => <Register />} />
            <Route path="/" render={() => <Home />} />
          </Switch>
        </div>

        <ButterToast
          position={{ vertical: POS_TOP, horizontal: POS_RIGHT }}
        />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
