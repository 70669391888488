import React, { useState, useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card } from 'react-bootstrap';
import electronics from "../../assets/electronics.png"
import property from "../../assets/property.png"
import car from "../../assets/car.png"
import home from "../../assets/home.png"
import dog from "../../assets/dog.png"
import services from "../../assets/services.png"
import sports from "../../assets/sports.png"
import beauty from "../../assets/beauty.png"
import grocery from "../../assets/grocery.png"
import education from "../../assets/education.png"
import other from "../../assets/other.png"
import agriculture from "../../assets/agriculture.png"
import { FaPhoneAlt, FaWhatsapp, FaEnvelope } from 'react-icons/fa'

function Home() {

    return (
        <div>
            <div id="home" className="web-view">
                <div className="container-fluid hero text-center pt-5 pb-2">
                    <Container>
                        <h1>Welcome to Shanikawa.LK</h1>
                        <p>Your one-stop solution for buying and selling in Sri Lanka</p>
                        <div class="search-bar">
                            <div>
                                <div className="input-group mb-3">
                                    <select className="form-select search-select-mobile-width">
                                        <option selected>All Ads</option>
                                        <option value="1">Used Cars</option>
                                        <option value="2">Mobile Phones</option>
                                        <option value="3">Properties</option>
                                        <option value="4">Jobs</option>
                                    </select>
                                    <select className="form-select search-select-mobile-width">
                                        <option selected>All Sri Lanka</option>
                                        <option value="1">Used Cars</option>
                                        <option value="2">Mobile Phones</option>
                                        <option value="3">Properties</option>
                                        <option value="4">Jobs</option>
                                    </select>
                                    <input type="text" class="form-control" placeholder="Search" aria-label="Search" id="searchInput" />
                                    <div className="input-group-append">
                                        <button className="main-search-btn" type="button" ><FontAwesomeIcon icon={faSearch} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <Container className="mt-4">
                    <div className="cus-img-thumbnail">
                        <br />
                        <Row>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={property} alt="Properties" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Properties</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={car} alt="Vehicles" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Vehicles</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={electronics} alt="Electronics" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Electronics</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={home} alt="Home & Furniture" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Home & Furniture</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={dog} alt="Animals" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Animals</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={services} alt="Services" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Services</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={sports} alt="Sports" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Sports & Toys</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={beauty} alt="Fashion & Beauty" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Fashion & Beauty</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={education} alt="Education" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Education</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={grocery} alt="Grocery" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Grocery Items</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <div>
                                        <Card.Img variant="top" src={agriculture} alt="Agriculture" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Agriculture</Card.Title>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={other} alt="Other" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Others</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                        </Row>
                        <br />
                    </div>
                </Container>
                <Container className="mt-4">
                    <div className="cus-img-thumbnail">
                        <div className="div-home-bottom-contact">
                            <h3>Want to get immediate help?</h3>
                            <h6>24 x 7 Contact Us Now</h6>
                            <Row className="mt-4">
                                <Col md={4} xs={4} className="d-flex justify-content-center align-items-center">
                                    <a href="tel:+94705333371" className="d-flex align-items-center home-contact-a">
                                        <div>
                                            <strong>Call</strong>
                                            <p className="home-contact-p"><FaPhoneAlt className="contact-icon home-contact-icons" />070 5 33 33 71</p>
                                        </div>
                                    </a>
                                </Col>
                                <Col md={4} xs={4}className="d-flex justify-content-center align-items-center">
                                    <a href="https://wa.me/94705333371" className="d-flex align-items-center home-contact-a">
                                        <div>
                                            <strong>WhatsApp</strong>
                                            <p className="home-contact-p"><FaWhatsapp className="contact-icon home-contact-icons" />070 5 33 33 71</p>
                                        </div>
                                    </a>
                                </Col>
                                <Col md={4} xs={4} className="d-flex justify-content-center align-items-center">
                                    <a href="mailto:support@Shanikawa.lk" className="d-flex align-items-center home-contact-a">
                                        <div>
                                            <strong>Email</strong>
                                            <p className="home-contact-p"><FaEnvelope className="contact-icon home-contact-icons" />support@Shanikawa.lk</p>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <br />
                </Container>
            </div>
            <div id="home" className="mobile-view">
                <div className="container-fluid hero text-center pt-5 pb-2">
                    <Container>
                        <h1>Welcome to Shanikawa.LK</h1>
                        <p>Your one-stop solution for buying and selling in Sri Lanka</p>
                        <div class="search-bar">
                            <Row className="form-search-select-row">
                                <select className="form-select form-search-select">
                                    <option selected>All Ads</option>
                                    <option value="1">Used Cars</option>
                                    <option value="2">Mobile Phones</option>
                                    <option value="3">Properties</option>
                                    <option value="4">Jobs</option>
                                </select>
                            </Row>
                            <Row className="form-search-select-row">
                                <select className="form-select form-search-select">
                                    <option selected>All Sri Lanka</option>
                                    <option value="1">Used Cars</option>
                                    <option value="2">Mobile Phones</option>
                                    <option value="3">Properties</option>
                                    <option value="4">Jobs</option>
                                </select>
                            </Row>
                            <Row>
                                <div className="input-group">
                                    <input type="text" class="form-control" placeholder="Search" aria-label="Search" id="searchInput" />
                                    <div className="input-group-append">
                                        <button className="main-search-btn" type="button" ><FontAwesomeIcon icon={faSearch} /></button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Container className="mt-4">
                    <div className="cus-img-thumbnail">
                        <br />
                        <Row>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={property} alt="Properties" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Properties</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={car} alt="Vehicles" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Vehicles</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={electronics} alt="Electronics" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Electronics</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={home} alt="Home & Furniture" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Home & Furniture</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={dog} alt="Animals" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Animals</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={services} alt="Services" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Services</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={sports} alt="Sports" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Sports & Toys</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={beauty} alt="Fashion & Beauty" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Fashion & Beauty</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={education} alt="Education" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Education</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={grocery} alt="Grocery" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Grocery Items</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <div>
                                        <Card.Img variant="top" src={agriculture} alt="Agriculture" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Agriculture</Card.Title>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                                <div className="text-center mb-4 mt-4">
                                    <Card.Body>
                                        <Card.Img variant="top" src={other} alt="Other" style={{ height: '80px', width: '80px' }} />
                                        <Card.Title>Others</Card.Title>
                                    </Card.Body>
                                </div>
                            </Col>
                        </Row>
                        <br />
                    </div>
                </Container>
                <Container className="mt-4">
                    <div className="cus-img-thumbnail">
                        <div className="div-home-bottom-contact">
                            <h3>Want to get immediate help?</h3>
                            <h6>24 x 7 Contact Us Now</h6>
                            <Row className="mt-4">
                                <Col md={4} className="d-flex justify-content-center align-items-center">
                                    <a href="tel:+94705333371" className="d-flex align-items-center home-contact-a">
                                        <div>
                                            <strong>Call</strong>
                                            <p className="home-contact-p"><FaPhoneAlt className="contact-icon home-contact-icons" />070 5 33 33 71</p>
                                        </div>
                                    </a>
                                </Col>
                                <Col md={4} className="d-flex justify-content-center align-items-center">
                                    <a href="https://wa.me/94705333371" className="d-flex align-items-center home-contact-a">
                                        <div>
                                            <strong>WhatsApp</strong>
                                            <p className="home-contact-p"><FaWhatsapp className="contact-icon home-contact-icons" />070 5 33 33 71</p>
                                        </div>
                                    </a>
                                </Col>
                                <Col md={4} className="d-flex justify-content-center align-items-center">
                                    <a href="mailto:support@Shanikawa.lk" className="d-flex align-items-center home-contact-a">
                                        <div>
                                            <strong>Email</strong>
                                            <p className="home-contact-p"><FaEnvelope className="contact-icon home-contact-icons" />support@Shanikawa.lk</p>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <br />
                </Container>
            </div>
        </div>
    );
}

export default Home;